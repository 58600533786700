import styled, { css } from 'styled-components';

import { StyledTabContainer } from '../SocialFeeds/styles';
import {CustomDropDown, CustomDropDownv3} from "../PeopleHomeV2/styles";
import { /*MenuItem,*/ DropdownButton, DropdownItem } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';

const BoldFont = 'Rubik-Medium';
const MediumFont = 'Rubik-Regular';

const OverlayDashboard = styled.div`
  opacity: 1;

  ${({showOverlay}) => showOverlay && css`
    opacity: 0.5;
    pointer-events: none;
  `}
`;

const DashboardLayout = styled.div`
  color: rgb(62,78,104);
  font-size: 30px;
  margin: ${({margin}) => margin ? margin : 'none'};
  padding: ${({padding}) => padding ? padding : '0px 25px'};
  @media (max-width: 650px) {
    text-align: center;
    font-size: 20px;
  }
`;

const HeaderWrapper = styled.div`
  float: left;
  width: 100%;
  padding: ${(props) => props.padding ? '5px 37px 20px 32px' : '30px 0 0 0'};
  @media (max-width: 500px) {
    padding: 30px 10px 20px;
  }
`;
const CardDashboard = styled.div`
  float: left;
  width: 100%;
  display: block;
  margin-top: ${({margin}) => (margin) ? '25px' : '0px'};
  @media (max-width: 500px) {
    padding: 0 10px 20px;
  }
`;

const CardDashboardN = styled.div`
  float: left;
  width: 100%;
  display: block;
  width: calc(100% / 2 - 12.5px);
  height: 405px;
  @media (max-width: 500px) {
    padding: 0 10px 20px;
  }
`;

const CardDashboardNew = styled.div`
  float: left;
  width: 100%;
  display: block;
  @media (max-width: 1225px) and (min-width: 501px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (max-width: 500px) {
    padding: 0 10px 20px;
  }
`;

const CardContainer = styled.div`
  float: left;
  width: calc((100% - 75px)/4);
  display: inline-block;
  margin-right: 25px;
  background: #fff;
  padding: 0 10px;
  border-radius: 4px;
  &:last-child {
    margin-right: 0;
  }
  
  @media (max-width: 1270px) {
    padding: 0 5px;
  }
  
  @media (max-width: 1225px) and (min-width: 501px) {
    width: calc((100% - 18px)/2);
    margin: 10px 0px 10px 0;
    padding: 0 10px;

    &:nth-child(even) {
      margin-right: 0;
    }
  }

  @media (max-width: 500px) {
    width: 100%;
    margin: 10px 0;
    padding: 0 10px;
  }
  
`;

const StyledTabs = styled(StyledTabContainer)`
float: none;
max-width: 1246px;
height: 60px;
margin: auto;
margin-top: 15px;
   .main-container {
      display: block;
    @media (max-width: 1214px){
      display: flex;
      justify-content: center;
    }
    @media (max-width : 700px){
      display: block;
    }
   }
  .tab-content {
    position: relative;
    
    @media (max-width: 600px) {
      margin-top: -48px;
    }
  }
  .CompanyInfoContainer{
    width:180px;
    margin-right: 15px;
    position: relative;
    bottom: 60px;
    height:0px;
    left: ${({ admin }) => admin ? '-200px' : '-150px'};
    .button{
      width:180px;
      height:40px;
      background: rgb(0, 92, 135);
      border-radius:6px;
      cursor:pointer;
      display:flex;
      justify-content:center;
      align-items:center;
      background:#005C87;
      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0px;
      text-align: center;
      color:white;
    }
  }
  .dropdown-container{
    margin-left: 115px;
    height: 65px;
    > label {
      font-family: Rubik;
     font-style: normal;
     font-weight: normal;
     font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #9C9C9C;
    margin-right: 10px;
    position: relative;
    bottom: ${({role}) => (role === 'ADMIN') ? '3px' : '2px'};
    right: ${({role}) => (role === 'ADMIN') ? '275px' : '0px'};
    }
    @media (max-width: 700px) {
      margin-left: 0px;
      margin-top: -75px;
    }
    @media (max-width: 510px) and (min-width: 501px) {
      margin-left: 30px;
    }
  }
  
  .company-container {
    float: left;
    margin-top: -51px;
    margin-right: 15px;
    position: relative;
    bottom: 30px;
    background-color: rgb(250, 249, 244);
    border: 10px solid rgb(175, 203, 211);
    border-radius: 6px;
    padding: 9px 10px;
    
    @media (max-width: 639px) {
      float: left;
      margin-left: 63px;
    }
    @media (max-width: 800px) {
      float: right;
      
    }
    @media (max-width: 500px) {
      display: inline-block;
      float: none;
      margin: 0 auto;
      padding-top: 10px;
    }
    @media (max-width: 600px) and (min-width: 501px) {
        display: inline-block;
        float: right;
        margin: 0 41px 0 33px;
        padding-top: 10px;
    }
   
  
  }
  
  > ul {
    @media (max-width: 1214px) {
      min-height: 135px;
      display: flex;
      justify-content: center;
    }
    @media (max-width: 500px) {
       min-height: ${({role}) => (role === 'ADMIN') ? '320px' : '270px'};
      display: block;
      text-align: center;
    }
    > li {
      @media (max-width: 600px) {
        float: none;
        /* width: 135px; */
        margin: 0 auto;
      }
      > a {
				margin-left: 15px;
        margin-right: 0px;
        border: none;
        font-size: 16px;
        color: #669db7;
        text-transform: none;
        font-family: ${MediumFont};
        
        @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
       }
      }
    }


    .active {
      > a {
        &:hover, &:active, &:focus {
          color: rgb(51,51,51);
        }
      }
    }
  }


    button .first-tab .nav-link .active{
      background:yellow;
    }


    li.nav-item {
      @media (max-width: 600px) {
        float: none;
        width: 135px;
        margin: 0 auto;
      }
      >button {
				margin-right: 3vw;
        font-size: 16px;
        color: rgb(102, 157, 183);
        text-transform: none;
        font-family: ${MediumFont};
        
        @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
       }
      }
    }

  li.nav-item {
    button.first-tab.nav-link.active  {
    background: rgb(242, 247, 249);
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: rgb(0, 92, 135);
    justify-content: center;
    padding: 10px;
    border-bottom: 3px solid  none;
    border: none;
    margin-top: -5px;
    }
  }
  li.nav-item {
    button.second-tab.nav-link.active  {
    background: rgb(242, 247, 249);
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: rgb(0, 92, 135);
    justify-content: center;
    padding: 10px;
    border-bottom: 3px solid  none;
    border: none;
    margin-top: -5px;
    }
  }
  li.nav-item {
    button.third-tab.nav-link.active  {
    background: rgb(242, 247, 249);
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: rgb(0, 92, 135);
    justify-content: center;
    padding: 10px;
    border-bottom: 3px solid  none;
    border: none;
    margin-top: -5px;
    }
  }
  li.nav-item {
    button.fourth-tab.nav-link.active  {
    background: rgb(242, 247, 249);
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: rgb(0, 92, 135);
    justify-content: center;
    padding: 10px;
    border-bottom: 3px solid  none;
    border: none;
    margin-top: -5px;
    }
  }
#styledTabs-tab-1{
  margin-left: 0;
  margin-top: -9px;
  margin-bottom: 11px;
  height: 37px;
  width: 129px;
  padding: 7px 15px;
  &:hover{
    background-color: #f2f7f9;
    border-radius: 6px;
  }
}
#styledTabs-tab-2{
  margin-top: -9px;
  margin-bottom: 11px;
  height: 37px;
  width: 87px;
  padding: 7px 15px;
  &:hover{
    background-color: #f2f7f9;
    border-radius: 6px;
  }
}
#styledTabs-tab-3{
  margin-top: -9px;
  margin-bottom: 11px;
  height: 37px;
  width: 114px;
  padding: 7px 15px;
  &:hover{
    background-color: #f2f7f9;
    border-radius: 6px;
  }
}
#styledTabs-tab-4{
  margin-top: -9px;
  margin-bottom: 11px;
  padding: 7px 15px;
  &:hover{
    background-color: #f2f7f9;
    border-radius: 6px;
  }
}

li.first-tab.active {
    > a {
      margin-left: 15px;
    }
}

  li.first-tab.active {
    > a {
    background: #f2f7f9;
    border-radius: 6px;
    margin-left: -3px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #005C87;
    justify-content: center;
    padding: 10px 15px !important;
    border-bottom: 3px solid none;
    border: none;
    /* margin-top: -5px; */
    }
  }
  li.second-tab.active {
    > a {
    background: #f2f7f9;
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #005C87;
    justify-content: center;
    padding: 10px !important;
    border-bottom: 3px solid  none;
    border: none;
    /* margin-top: -5px; */
    }
  }
  li.third-tab.active {
    > a {
      background: #f2f7f9;
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #005C87;
    justify-content: center;
    padding: 10px !important;
    border-bottom: 3px solid  none;
    border: none;
    /* margin-top: -5px; */
      
  }
}
li.fourth-tab{
  > a {
   margin-bottom:8px;
   height:37px;
  }
}
  li.fourth-tab.active {
    > a {
      background: #f2f7f9;
      border-radius: 6px;
      font-family: 'Rubik-Medium';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      text-align: center;
      color: #005C87;
      justify-content: center;
      padding: 10px 15px!important;
      border-bottom: 3px solid  none;
      border: none;
      margin-bottom: 8px !important;
      /* margin-top: -5px; */
      
    }
  }
  .dropdown-menu {
    min-width: ${({newWidth}) => newWidth ? '0px' : '54px'};
    text-align: ${({newWidth}) => newWidth ? 'center' : 'none'};
    margin-left: ${({newWidth}) => newWidth ? '-62px' : '0px'};
     margin-top: ${({role,newWidth}) => (role === 'ADMIN') ? '-20px' :  newWidth ?  '-1px' : '0px'};
    > li {
        > a {
          padding: 5px 15px;
        }
      }
    > ul {
      max-width: 300px;
    }
    @media (max-width: 740px) and (min-width: 501px){
      margin-right: 38px;
    }
  }
`;

const Row1 = styled.div`
display:flex;
  float: left;
  width: 100%;
  bottom: ${({paddingTop}) => paddingTop ? '8px' : '0' };
  padding: 10px 0 0;
  position: relative;
  .textdata{
    font-size: 16px;
    float: left;
    margin: 0;
    text-transform: capitalize;
    color: rgb(0, 92, 135);
    padding: 10px 0 0 17px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    line-height: 11px;
  }
  
  & > p {

      font-size: 12px;
    float: left;
    margin: 0px;
    text-transform: uppercase;
    color: rgb(111, 118, 126);
    padding: 13px 0px 0px 7px;
    line-height: 17px;
    font-family: Rubik-Medium;
    font-weight: 700;
       @media (max-width: 1290px) and (min-width: 1225px) {
       max-width: 120px;   
    }
  }
  
  & > i {
    float: right;
    font-size: 17px;
    color: #999999;
  }
  
  img {
    width: 50px;
    height: 50px;
    float: right;
  }
  .totaldata {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
    font-size: ${({font}) => font ? '14px' : '16px' };
    font-family: 'Rubik-Medium';
    color: #3a3a3a;
    >span{
      font-size: ${({font}) => font ? '14px' : '16px' };
      font-family: 'Rubik-Medium';
      color: #3a3a3a;
      padding-left: 2px;
    }
  }
`;

const ImgContainer = styled.div`
    float: left;
    width: ${({width}) => width ? width : '52px' };
    height: ${({height}) => height ? height : '52px' };
    background-color: ${({bgColor }) => bgColor ? bgColor : '#fff'};
    display: flex;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    div{

      & > img {
       width: 50px;
       height: 50px;
      
    }

    }
    & > img {
       width: 50px;
       height: 50px;
      
    }
`;

const NewImgContainer = styled.div`
   width: 100%
    
    & > img {
       width: 60%;
       height: 60%;
      
    }
`;
const TotalCount = styled.div`
    font-size: 35px;
    margin-top: 10px;
    float: left;
    font-family: ${BoldFont};
    color: ${({color}) => color ? color : '#3e4e68'};
`;

const TotalCountNew = styled.div`
    font-size: 16px;
    margin-top: 10px;
    float: left;
    font-family: ${BoldFont};
    color: ${({ bgcolor }) =>  bgcolor ? bgcolor : '#fff'};
`;

const CSVButton = styled.button`
  min-height: 40px;
  border-radius: 22px;
  background-color: rgba(159,201,137, 0.1);
  border: none;
  position: relative;
  float: right;
  margin-top: -38px;
  margin-right: ${({role}) => (role === 'ADMIN') ? '-40px' : '0px'};
  left: ${({role}) => (role === 'ADMIN') ? '' : '100px'};
  font-size: 11px;
  color: #3a3a3a;
  font-family: ${MediumFont};
  text-align: center;
  font-size: 16px;
  outline: none;
  padding: 10px;
  margin-top: -75px;
  border-radius: 8px;
  width: 13%;
  opacity: ${({disabled}) => disabled ? '0.5' : '1' };
  cursor: ${({disabled}) => disabled && 'not-allowed !important'};
  &:hover {
    color: #0169b2;
  }
  @media (max-width: 800px) {
    float: left;
    margin-left: 46px;
  }
  @media (max-width: 694px) {
   margin-top: ${({role}) => (role === 'ADMIN') ? '' : '-35px'};
  }
  @media (max-width: 650px) and (min-width: 601px){
   margin-top: ${({role}) => (role === 'ADMIN') ? '-56px' : ''};
  }
  @media (max-width: 600px){
     margin-top: ${({role}) => (role === 'ADMIN') ? '-56px' : '0px'};
  }@media (max-width: 552px){
     margin-top: ${({role}) => (role === 'ADMIN') ? '-56px' : '-30px'};
   }
  @media (max-width: 500px) {
    margin-top: ${({role}) => (role === 'ADMIN') ? '-130px' : '-6px'};
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .iyeGxG {
    margin-top: -50px;
  }
  .dHVzbH {
    width: 12%;
    min-height: 40px;
  }
  .glyphicon {
    margin-right: 5px;
  }
  img{
    width: 20px;
    height:
  }
   .bsmdNs {
     width: 13%;
   }
  
`;
const YearDropDown = styled(CustomDropDown)`
  button{
    border: 1px solid rgb(205, 203, 203);
    background-color: rgb(250, 249, 244) !important;
    color: rgb(0, 92, 135);
    width:90px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    height: 40px;
  }
  button:active{
    border: 1px solid rgb(205, 203, 203);
    background-color: rgb(250, 249, 244);
    color: rgb(0, 92, 135);
  }
  button:hover{
    border: 1px solid rgb(205, 203, 203);
    background-color: rgb(250, 249, 244);
    color: rgb(0, 92, 135);
  }
  button:hover:active{
    border: 1px solid rgb(205, 203, 203);
    background-color: rgb(250, 249, 244);
    color: rgb(0, 92, 135);
  }
  button:focus-visible{
    border: 1px solid rgb(205, 203, 203);
    background-color: rgb(250, 249, 244);
    color: rgb(0, 92, 135);
  }
  float: right;
  align-items: center;
  ${'' /* margin-left: 60px; */}
  padding: ${({inputpadding}) => inputpadding ? inputpadding : '9px 29px'};
  width: ${({width}) => width ? width : '90px'};;
  height: 40px;
  ${'' /* background-color: rgba(105, 194, 255, 0.1); */}
  border-radius: 6px;
  box-shadow: none;
  ${'' /* margin-top: ${({role}) => (role === 'ADMIN') ? '-62px' : '0px'}; */}
  @media (max-width: 760px) and (min-width: 651px) {
     margin-top: ${({role}) => (role === 'ADMIN') ? '-62px' : ''};
  }
  @media (max-width: 650px){
    margin-top: ${({role}) => (role === 'ADMIN') ? '-54px' : '-36px'};
  }
  @media (max-width: 500px){
    margin-left: 0px;
    margin-top: -40px;
  }
  .dropdown-menu{
    font-size: 14px !important;
  }
  .dropdown-item{
    width: 10px !important;
  }
`;

const YearDropDownV3 = styled(CustomDropDownv3)`
  float: right;
  align-items: center;
  margin-left: 60px;
  padding: ${({inputpadding}) => inputpadding ? inputpadding : '9px 29px'};
  width: 90px;
  height: 40px;
  background-color: #faf9f4;
  border: 1px solid #afcbd3;
  border-radius: 6px;
  box-shadow: none;
  margin-top: ${({role}) => (role === 'ADMIN') ? '-62px' : '-0px'};
  @media (max-width: 760px) and (min-width: 651px) {
     margin-top: ${({role}) => (role === 'ADMIN') ? '-62px' : ''};
  }
  @media (max-width: 650px){
    margin-top: ${({role}) => (role === 'ADMIN') ? '-54px' : '-36px'};
  }
  @media (max-width: 500px){
    margin-left: 0px
  }
`;

const DropDownCompanayContainer = styled.div`
  min-width: ${({width}) => width ? width : '310px'};
  width: auto;
  @media (max-width: 760px) {
    min-width: 310px;
  }
  @media (max-width: 650px) {
    min-width: 410px;
  }
  @media (max-width: 500px) {
    min-width: unset;
  }
  button{
    margin:0px 0px 0px 25px;
    width:80%;
  }
`;

const CreateButton = styled.div`

`

const Button = styled.div`
  float: right;
  margin-top: 30px;
`;
const NewLabel = styled.label`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
text-align: center;
color: rgb(102, 157, 183);
${'' /* margin-right: 10px; */}
position: relative;
${'' /* right: 275px; */}
bottom: ${({role}) => (role === 'ADMIN') ? '3px' : '0px'};
`;

const HeaderWrapperV2 = styled.div`
width: 100%;
max-width: 1246px;
margin: auto;
`;

const NumberWrapper = styled.div`
  width:100%;
  display:flex;
  justify-content:center;
  align-items: center;
  flex-wrap: wrap;
  .crossIcon{
    width:100%;
    display:flex;
    justify-content:flex-end;
    // height:auto;
    margin-top:10px;
    margin-right:10px;
    >img{
      width:24px;
      height:24px;
    }
    >div{
    >svg{
      cursor:pointer;
    }
  }
  }
  .wrapper{
    width:100%;
    display:flex;
    justify-content:center;
    align-items: center;
    padding: 25px 0px;
  }
  .stepHeading{
    font-family: Rubik-Medium;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color:#005C87;
    display:flex;
    align-item:center;
    width:100%;
    justify-content:center;
  }
  .default{
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color:#005C8799;
    display:flex;
    align-item:center;
    width:100%;
    justify-content:center;
    margin-top:5px;
    padding-bottom:15px;
  }
`;

const Number = styled.div`
  width:40px;
  height:40px;
  display:flex;
  border-radius:6px;
  background: ${({disable}) => disable ? '#005C871A' : '#85C0EA'};
  font-family: "Rubik-Medium";
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({disable}) => disable ? '#005C8799' : 'white'};
  justify-content:center;
  align-items:center;
  margin: ${({margin}) => margin ? margin : '0px'};
`;

const ContentWrapper = styled.div`
  width:100%;
  display:flex;
  padding: ${({padding, paddingValue}) => paddingValue?paddingValue:padding ? "15px 25px 20px 25px" : '25px 25px 35px 25px'};
  flex-wrap: wrap;
  border: ${({border}) => border ? "" : '1px solid #9C9C9C66'};
  .notMatched{
    font-family:rubik;
    font-size:12px;
    color:#F4AAA9;
    line-height:20px;
    margin-top:5px;
  }
`;

const Title = styled.div`
  width:100%;
  display:flex;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color:#005C87;
  margin: ${({margin}) => margin ? margin : '0px'};
  >span{
    color:#FD7175;
  }
  >img{
    width:16px;
    height:16px;
    display: flex;
    margin: auto;
    margin-left: 10px;
  }
  .edit{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: right;
    color: #FD7175;
    display:flex;
    margin:auto;
    margin-right:0px;
    text-decoration:underline;
    cursor:pointer;
  }
  .default{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: right;
    color: #FD7175;
    display:flex;
    margin:auto;
    margin-right:0px;
    text-decoration:underline;
    cursor:pointer;
  }
`;

const ImageWrapper = styled.div`
  width:100%;
  display:flex;
  .logo{
    min-width:150px;
    max-width:150px;
    height:150px;
    border: 1px dashed #9C9C9C;
    border-radius:6px;
    background: rgba(156,156,156,.04);
    display: flex;
    align-items: center;
    justify-content:center;
    >img{
      min-width:140px;
      max-width:140px;
      height:auto;
      border-radius:6px;
    }
    .image{
      width:120px;
      height:30px;
      display: flex;
      margin: auto;
    }
  }
  .imageContentWrapper{
    display:block;
    padding-left:25px;
    .button{
      width:100%;
      margin-bottom: 20px;
    }
    .note{
      font-family: Rubik;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color:#9c9c9c;
    }
  }
`;

const ImageButton = styled.div`
  width: ${({width}) => width ? width : '165px'};
  height:50px;
  display:flex;
  background: ${({background}) => background ? background : '#85C0EA'};
  border-radius:6px;
  cursor:pointer;
  cursor: ${({disable}) => disable ? "not-allowed" : 'pointer'};
  font-family: Rubik-Medium;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({color}) => color ? color : 'white'};
  border: ${({border}) => border ? "1px solid #005C87" : 'none'};
  justify-content: center;
  align-items: center;
  margin: ${({margin}) => margin ? margin : '0'};
  >input{
    opacity: 0;
    position: absolute;
    width: 150px;
    height: 40px;
    cursor:pointer;
  }
`;

const DropdownArrow = styled.img`
  float: right;
  top:${({ drop, top }) =>top?top: drop ? "93px" : " 90px"};
  position: absolute;
  right: 15px;
  display:block;
  z-index:5;
`;

const ActivityDropdown = styled(DropdownButton)`
  width: ${({ widthValue }) => (widthValue ? widthValue : "100%")};
  text-align: left;
  margin-top: 0px;
  margin: ${({ margin }) => margin};
  text-transform: capitalize;
  height: 60px;
  color: ${({ notActive }) => (notActive ? "#005C874D" : "#005C87")};
  font-size: 16px;
  font-family: ${({ notActive }) => (notActive ? "rubik" : "rubik-medium")};
  border: 1px solid #005c874d;
  cursor: ${({ disabled }) => disabled && "not-allowed !important"};
  background: rgba(156, 156, 156, 0.04);
  > span {
    display: none;
  }
  button {
    width: 100%;
    height: 100%;
    padding: 0px 20px;
    width: 100%;
    text-align: left;
    margin-top: 0px;
    text-transform: capitalize;
    height: 60px;
    color: rgb(0, 92, 135);
    font-size: 16px;
    font-family: rubik-medium;
    border: 1px solid rgba(0, 92, 135, 0.3);
    background: rgba(156, 156, 156, 0.04);
    ::after {
      display: none;
    }
    &:hover{
      border: 1px solid rgba(0, 92, 135, 0.3);
      background: rgba(156, 156, 156, 0.04);
      color: rgb(0, 92, 135);
    }
    &:active:focus{
      border: 1px solid rgba(0, 92, 135, 0.3);
      background: rgba(156, 156, 156, 0.04);
      color: rgb(0, 92, 135);
    }
    &:focus{
      -webkit-box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
      box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
      background: rgba(156, 156, 156, 0.04);
      color: rgb(0, 92, 135);
      border: 1px solid rgba(0, 92, 135, 0.3);
    }
  }
  .dropdown-menu {
    width: 50%;
    height: 100px;
    overflow-y: auto;
    position: absolute;
    border: 1px solid rgba(0, 92, 135, 0.3);
    padding: 5px 15px;
    .dropdown-item {
      text-transform: capitalize;
      padding: 5px 0px;
      border-bottom: 1px solid rgba(0, 92, 135, 0.1);
      width: 100% !important;
      display: flex;
      color: rgba(0, 92, 135, 0.6);
      font-family: Rubik;
      font-size: 14px;
      line-height: 20px;
      padding: 3px 0px 3px 15px;
    }
    .dropdown-item > img {
      width: 16px;
      height: 16px;
      display: none;
      margin: auto 15px auto auto;
    }
    .dropdown-item:hover > img{
      display: flex;
    }
    .dropdown-item:active > img{
      display: flex;
    }
    .dropdown-item.active{
      text-transform: capitalize;
      border-bottom: 1px solid rgba(0, 92, 135, 0.1);
      width: 100% !important;
      background: rgba(0, 92, 135, 0.05);
      color: rgb(0, 92, 135);
      font-family: Rubik-medium;
      font-size: 14px;
      line-height: 20px;
      padding: 3px 0px 3px 15px;
    }
  }
  &:active,
  &:focus,
  &:active:focus {
    outline: none;
    background-color: white;
    box-shadow: none;
    // color: #9c9c9c;
    color: #005c87;
    font-size: 16px;
    font-family: "rubik-medium";
    border: 1px solid #005c874d;
  }
  &:hover {
    background: rgba(156, 156, 156, 0.04);
    color: #005c87;
    border: 1px solid #005c874d;
  }
  .btn-default.active,
  .btn-default:active,
  .open > .dropdown-toggle.btn-default {
    border: 1px solid #005c874d;
  }
  .btn-default.active.focus,
  .btn-default.active:focus,
  .btn-default.active:hover,
  .btn-default:active.focus,
  .btn-default:active:focus,
  .btn-default:active:hover,
  .open > .dropdown-toggle.btn-default.focus,
  .open > .dropdown-toggle.btn-default:focus,
  .open > .dropdown-toggle.btn-default:hover {
    color: #005c87;
  }
`;

const CustomMenuItem = styled(DropdownItem)`
  width: 100% !important;
  text-transform: capitalize;
  padding: 5px 0;
  border-bottom: 1px solid #005C871A;
  &:last-child{
    border:none;
  }
  > a {
    width:100%;
    display: flex;
    align-items: center;
    display: flex;
    padding-left: 15px;
    color:#005C87;
    >img{
      width:16px;
      height:16px;
      display: flex;
      margin: auto;
      margin-right: 15px;
      display:none;
    }
    &:active, &:hover, &:focus {
      outline: none;
      color:#005C87!important;
      background-color:#005C870D!important;
      border-radius:6px !important;
      font-family:"Rubik-Medium"!important;
      font-size:14px;
      line-height:20px;
      >img{
        width:16px;
        height:16px;
        display: flex;
        margin: auto;
        margin-right: 15px;
      }
    }
  }
`;

const TitleContainer = styled.div`
  float: left;
  width: ${({ width }) => width ? width : '100%'};
  padding: ${({ padding }) => padding ? padding : '15px 0px 10px 0px'};
  margin: ${({ margin }) => margin};
  position: relative;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  .open>.dropdown-toggle.btn-default:focus, .open>.dropdown-toggle.btn-default:hover{
    background:#F7F5EB80;
    color:#005C87;
    border: 1px solid #005C874D;
    background:rgba(156,156,156,.04);
  }
  .btn-default.active, .btn-default:active, .open>.dropdown-toggle.btn-default{
    background:#F7F5EB80;
    border: 1px solid #005C874D;
  }
  > div {
    float: left;
    width: 100%;
    position: relative;
  }
  .main{
    display: inline-block;
    width: 100%;
    >span{
      font-size: 12px;
    }
  }
  .outerDiv{
    display: flex;
    >div:first-child{
      width: 70%;
    }
    >div:last-child{
      width: 30%;
      display: flex;
      .padding{
        padding-top: 10px;
      }
      >div:first-child{
        height: 100%;
        width: 20%;
        >label{
          margin: 0;
        }
      }
      >div:last-child{
        width: 80%;
      }
    }
  }

  ${({ addMargin }) => addMargin && css`
    > div {
      margin-top: 10px;
    }
  `}

  @media (max-width: 550px) {
    padding: 30px 15px 30px;
  }

  svg {
    position: absolute;
    top: 15px;
    right: 0;
  }

  .dropdown-menu {
    display: ${({ display }) => display ? display : ""};
    width:${({ fullWidth, widthValue }) => widthValue ? widthValue : fullWidth ? "100%" : "50%"}
    height: ${({ height }) => height ? height : '200px'};
    overflow-y: auto;
    position:absolute;
    position: ${({ position }) => position ? "relative" : "absolute"};
    padding: 0 15px;
    border: 1px solid #005C874D;
    padding:5px 15px;
    >li
      >a{
      display:flex;
      color: #005C8799;
      font-family:"Rubik";
      font-size:14px;
      line-height:20px;
      padding: 3px 0 3px 15px;
      }
      >.active>a:hover {
        color: #005C87;
        font-family:"Rubik-medium";
        font-size:14px;
        line-height:20px;
      }
    }

  .dropdown-menu>.active>a{
    background:#005C870D;
    color: #005C87;
    font-family:"Rubik-medium";
    font-size:14px;
    line-height:20px;
    padding: 3px 0 3px 15px;
    >img{
      width:16px;
      height:16px;
      display: flex;
      margin: auto;
      margin-right: 15px;
    }
  }
  .dropdown-menu>.active>a:hover {
    color: #005C87;
    font-family:"Rubik-medium";
    font-size:14px;
    line-height:20px;
    background:#F7F5EB80;
  }
  @media (max-width: 1000px){
    width: 100%;
  }
`;

const DisableColor = styled.div`
  width:100%;
  height: 50px;
  border-radius:6px;
  background: #005C87;
  display:flex;
  justify-content:center;
  cursor: not-allowed;
  >img{
    height:16px;
    width:16px;
    display:flex;
    margin:auto;
  }
`;

const StyledInputV2 = styled.input`
  border: none;
  padding: 0px 20px 0px 20px
  box-shadow: none;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  height: 60px;
  font-family: rubik-medium;
  font-weight:500;
  width: 100%;
  border-radius:6px;
  color:${({ color }) => color ? color : 'rgba(0, 47, 71, 1)'};
  border:${({ border }) => border ? border : '1px solid #cdcbcb'};
  background-color:${({ bgColor }) => bgColor ? bgColor : 'rgba(156, 156, 156, .04)'};
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};

  &:active{
    border:${({ border }) => border ? border : '1px solid #9c9c9c'};
  }
  &::placeholder {
    font-size: 16px;
    color: #9c9c9c;
    color:${({ pColor }) => pColor ? pColor : '#9c9c9c'};
    font-family: rubik;
    font-weight: 400;
    vertical-align: middle;
    line-height: 20px;
  }

  &:focus, &:active {
    outline: none;
  }
  
`;

const ColorInput = styled.div`
  width:100%;
  height:50px;
  border-radius:6px;
  cursor:pointer;
  >input{
   width:100%;
   height:50px;
   border:none;
   border-radius:6px;
   padding:0px;
   cursor:pointer;
  }
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius:6px;
  }
  input[type="color"]::-webkit-color-swatch {
    border: none;
    border-radius:6px;
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    transform: ${({transform}) => transform ? 'translate(0, 15vh) translate(0, -15%) !important' : 'translate(0, 50vh) translate(0, -50%) !important'};
    margin: ${({change}) => change ? '150px auto' : '0 auto'};
    .modal-content{
      border:none;
    }
    @media (max-height: 460px) {
      transform: none !important;
      margin: 30px auto;
    }
    width: ${({width}) => width ? width : '490px'};
    height: ${({change, height}) => height? height :change ? '573px' : '473px'};
    @media (max-width: 500px){
      width: 95%;
      height: auto;
    }
  }

  .modal-body{
    padding: ${({padding}) => padding ? padding : '25px 0 25px 0'};
  }
  
  .modal-container {
   width: 100%;
   display: block;
   border: none;
   > div: nth-child(1) {
    width: 100%;
    height: 0px;
    display: flex;
    justify-content: flex-end;
    >img{
      width: 24px;
      height: 24px;
      cursor: pointer;
      margin: 15px 15px 0 0;
    }
   }
   > div: nth-child(2) {
    width: 100%;
    display:flex;
    justify-content: center;
    font-family: Rubik-Medium;
    font-size: 24px;
    line-height: 24px;
    color:rgb(0, 92, 135);
    text-decoration: underline;
    margin-top: 35px;
   }
  }
`;

const StyledInputPassword = styled.div`
  border: none;
  // padding: 0px 20px 0px 20px
  box-shadow: none;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  height: 60px;
  font-family: rubik-medium;
  font-weight:500;
  width: 100%;
  border-radius:6px;
  color:${({ color }) => color ? color : 'rgba(0, 47, 71, 1)'};
  border:${({ border }) => border ? border : '1px solid #cdcbcb'};
  // background-color:${({ bgColor }) => bgColor ? bgColor : 'rgba(156, 156, 156, .04)'};
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  display:block;
  .icon{
    // width:45px;
    background-color:${({ bgColor }) => bgColor ? bgColor : 'rgba(156, 156, 156, .04)'};
    display: flex;
    position: relative;
    bottom: 45px;
    height:0px;
  >img{
    width:30px;
    height:auto;
    display:flex;
    margin:auto;
    margin-right:15px;
    cursor:pointer;
    // background-color:${({ bgColor }) => bgColor ? bgColor : 'rgba(156, 156, 156, .04)'};
  }
  .correctPassword{
    width:22px;
    height:22px;
    display:flex;
    margin:auto;
    margin-right:15px;
    // background-color:${({ bgColor }) => bgColor ? bgColor : 'rgba(156, 156, 156, .04)'};
  }}
  >input{
    border: none;
    padding: 0px 50px 0px 20px
    box-shadow: none;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
    height: 60px;
    font-family: rubik-medium;
    font-weight:500;
    // width: 100%;
    border-radius:6px;
    color:${({ color }) => color ? color : 'rgba(0, 47, 71, 1)'};
    // border:${({ border }) => border ? border : '1px solid #cdcbcb'};
    background-color:${({ bgColor }) => bgColor ? bgColor : 'rgba(156, 156, 156, .04)'};
    cursor: ${({ disabled }) => disabled && 'not-allowed !important'};



    &:active{
      border:${({ border }) => border ? border : '1px solid #9c9c9c'};
    }
    &::placeholder {
      font-size: 16px;
      color: #9c9c9c;
      color:${({ pColor }) => pColor ? pColor : '#9c9c9c'};
      font-family: rubik;
      font-weight: 400;
      vertical-align: middle;
      line-height: 20px;
    }
  
    &:focus, &:active {
      outline: none;
    }
  }
  
`;


const CheckBoxContainer = styled.div`
width: 100%;
display: flex;
align-items: center;
margin-top:10px;
.icon{
   width: 57px;
   align-items: center;
   display: flex;
}
`;

const CustomLabel = styled.label`
 position: relative;
 display: inline-block;
 width: 39px;
 height: 20px;
 margin-bottom: 0;

 @media (max-width: 500px) {
   left: calc(100% - 60px);
 }
 
 > input {
   display: none;
 }
 
 > input:checked + .slider {
   background-color: #76AB78;
 }

 > input:focus + .slider {
   box-shadow: 0 0 1px #76AB78;
 }
 
 > input:checked + .slider:before {
   -webkit-transform: translateX(18px);
   -ms-transform: translateX(18px);
   transform: translateX(18px);
 }
`;

const CustomCheckbox = styled.input`
 cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
`;

const Slider = styled.span`
 position: absolute;
 cursor: pointer;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 background-color: ${({ background }) => background ? background : '#69c2ff'};
 -webkit-transition: .4s;
 transition: .4s;
 border-radius: 34px;
 
 &:before {
   position: absolute;
   content: "";
   height: 18px;
   width: 18px;
   left: 0px;
   bottom: 2px;
   background-color: white;
   -webkit-transition: .4s;
   transition: .4s;
   border-radius: 50%;
 }
 cursor: ${({ disabled }) => disabled ? 'not-allowed !important' : 'pointer'};
`;

const DownloadWrapper = styled.div`
  display:flex;
  position:relative;
  ${'' /* float: right; */}
  ${'' /* margin-top: -75px; */}
  ${'' /* margin-right: -45px; */}
  ${'' /* margin-right: ${({ admin }) => admin ? '-45px' : '-95px'}; */}
`;

const YearDropdown = styled.div`
  display:flex;
  .dropdown-item{
    width:100% !important;
  }
  .dropdown-menu{
    min-width: 65px !important;
  }
  >div >label{
    display: flex;
    align-items: center;
  }
}
>div>div{
display:flex;
}
`;

const DropdownWrapper =styled.div`
div{
  .dropdown{
    button{
      background: none;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0px;
      padding: 0px;
      &:active{
        background: none;
        border: none;
      }
    }
    .dropdown-menu.show{
      display: inline-flex;
      padding: 12px;
      justify-content: center;
      align-items: center;
      transform: translate3d(-13px, 50px, 0px);
      border-radius: 4px;
      border: 1px solid rgba(0, 92, 135, 0.30);
      background: #FFF;
      display: flex;
      flex-direction: column;
      a{
        color: #007AB1;
        font-family: Rubik-medium;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        &:active{
          background: none;
        }
      }
      hr{
        width: 90%;
        color: rgba(0, 92, 135, 0.50);
      }
    }
  }
}
`;

export { OverlayDashboard, DashboardLayout, CardDashboard, CardContainer, HeaderWrapper,YearDropDownV3,
  StyledTabs, Row1, ImgContainer, TotalCount, CSVButton, YearDropDown, DropDownCompanayContainer, CreateButton,NewLabel,
  NewImgContainer, Button, CardDashboardNew, TotalCountNew, CardDashboardN, HeaderWrapperV2, NumberWrapper, Number, ContentWrapper, ImageButton, ImageWrapper, Title, DropdownArrow, CustomMenuItem, ActivityDropdown, TitleContainer, DisableColor, StyledInputV2, ColorInput, StyledModal, StyledInputPassword, CheckBoxContainer, CustomLabel, CustomCheckbox, Slider, DownloadWrapper, YearDropdown, DropdownWrapper};