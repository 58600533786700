import styled from 'styled-components';

import { StyledTabContainer } from '../SocialFeeds/styles';

const DashboardLayout = styled.div`
  color: rgb(62,78,104);
  font-size: 30px;
  @media (max-width: 760px) {
    text-align: center;
    font-size: 20px;
  }
`;

const StyledSvg = styled.svg`
  height: ${(props) => props.height ? props.height+' !important' : '27px'};
  width: ${(props) => props.width ? props.width+' !important' : '48px'};;
  float: left;
  fill:  ${(props) => props.fill ? props.fill: 'white' };
  padding-top: 2px;
`;

const DropDownContainer = styled.div`
  width: auto;
  min-width: 177px;
  float: right;

  @media (max-width: 760px) {
    text-align: left;
  }

  @media (max-width: 500px) {
    margin-top: 10px;
  }
`;

const StyledTabs = styled(StyledTabContainer)`
  > ul {
		margin: ${({margin}) => margin};

    @media (max-width: 500px) {
      padding: 20px 10px 1px;
    }
    > li {
      > a {
        font-size: 15px;
        color: rgb(51,51,51);
      }
    }

    .active {
      > a {
        color: rgb(51,51,51);
        &:hover, &:active, &:focus {
          color: rgb(51,51,51);
        }
      }
    }
  }

  li.first-tab.active {
    > a {
      border-bottom: 3px solid rgb(21,159,202);
    }
  }
  li.second-tab.active {
    > a {
      border-bottom: 3px solid #fbad19;
    }
  }
  li.third-tab.active {
    > a {
      border-bottom: 3px solid #93bf3d;
    }
  }
`;

export { DashboardLayout, StyledSvg, DropDownContainer, StyledTabs };
